import React from 'react';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import { PageProps } from 'gatsby';

import { LocaleNameSpaceKeys } from '../../localization/translations';
import Layout, { LayoutOptions, HeroSectionColors } from '../../components/Layout/Layout';
import DownloadBlock, { DownloadLink } from '../../components/DownloadBlock/DownloadBlock';
import PageMenu from '../../components/PageMenu/PageMenu';
import brokerCentreMenu from '../../data/page-menus/broker-centre';
import { ButtonLinkSize } from '../../components/ButtonLink/ButtonLink';
import { getPathFromRouteKey } from '../../shared/utils/getPathFromRouteKey';
import RouteKeys from '../../../config/RouteKeys';
import { IconTypes } from '../../components/Icon/Icon';
import MagicCarousel from '../../components/MagicCarousel/MagicCarousel';
import InfoBlock from '../../components/InfoBlock/InfoBlock';
import { useLocaleContext } from '../../context/Locale';
import { LocaleTag } from '../../../config/locales';

import './style.scss';

type Props = PageProps & WithTranslation;

const BrokerCentre: React.FC<Props> = (props) => {
  const { t } = props;
  const { activeLocaleTag } = useLocaleContext();

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('seo.title'),
    },
    page: {
      title: t('heading'),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.PARROT_GREEN,
        heroImage: {
          fileName: 'hero-image-4.jpg',
          alt: 'Image',
        },
        blockText: t('tagline'),
        shouldBeDigiBlock: true,
      },
    },
  };

  const brokerPackageDownLoadLinks: DownloadLink[] = [
    activeLocaleTag === LocaleTag.EN
      ? {
          to: `/downloads/general/HaventreeBank_BrokerPackage_2024-10-23.pdf`,
          label: t(`${LocaleNameSpaceKeys.COMMON}:brokerPackage`),
        }
      : {
          to: `/downloads/general/BanqueHaventree_troussepourlecourtiers_2024-10-23.pdf`,
          label: t(`${LocaleNameSpaceKeys.COMMON}:brokerPackage`),
        },
  ];

  return (
    <Layout options={layoutOptions} className="BrokerCentre">
      <div className="grid-container">
        <div className="row row-wrap">
          <section className="column column-60">
            <p>
              <Trans t={t} i18nKey="description" />
            </p>
            <div className="BrokerCentre__generic-gap">
              <DownloadBlock downloadLinks={brokerPackageDownLoadLinks} />
            </div>
          </section>
          <aside className="column column-33">
            <PageMenu
              menu={brokerCentreMenu}
              menuTitle={t(`${LocaleNameSpaceKeys.COMMON}:pageMenuTitles.brokerResources`)}
            />
          </aside>
        </div>
        <div className="BrokerCentre__large-gap BrokerCentre__info-block-wrapper">
          <MagicCarousel
            ariaNavItemLabels={(t('contentBlock.blocks', { returnObjects: true }) as any[]).map(
              (block) => block.heading
            )}
          >
            {(t('contentBlock.blocks', { returnObjects: true }) as any[]).map((block) => (
              <div className="row row-wrap" key={block.heading}>
                <div className="column column-60">
                  <InfoBlock
                    icon={block.icon as IconTypes}
                    buttonOptions={{
                      to: getPathFromRouteKey(
                        block.path
                          .split('.')
                          .reduce((accum: any, next: any) => accum[next], RouteKeys)
                      ),
                      label: t(`${LocaleNameSpaceKeys.COMMON}:learnMore`),
                      iconOptions: {
                        icon: IconTypes.ARROW_NEXT,
                      },
                      styleOptions: {
                        isInline: true,
                        size: ButtonLinkSize.SMALL,
                      },
                    }}
                    heading={<h2 className="h5">{block.heading}</h2>}
                  >
                    <p>
                      <Trans>{block.body}</Trans>
                    </p>
                  </InfoBlock>
                </div>
              </div>
            ))}
          </MagicCarousel>
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.BROKER_CENTRE)(BrokerCentre);
