import React, { memo, ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonLink, {
  ButtonLinkIconOptions,
  ButtonLinkLinkOptions,
  ButtonLinkStyleOptions,
  ButtonLinkType,
  ButtonLinkSize,
} from '../ButtonLink/ButtonLink';
import { IconTypes } from '../Icon/Icon';
import { LocaleNameSpaceKeys } from '../../localization/translations';
import './style.scss';

export interface DownloadLink {
  to: string;
  label: string;
  buttonOptionOverrides?: {
    iconOptions?: ButtonLinkIconOptions;
    linkOptions?: ButtonLinkLinkOptions;
    styleOptions?: ButtonLinkStyleOptions;
  };
}

interface OwnProps {
  heading?: string | ReactNode;
  body?: any;
  className?: string;
  children?: React.ReactNode;
  downloadLinks: DownloadLink[];
}

type Props = OwnProps;

const DownloadBlock: React.FC<Props> = ({
  heading,
  body,
  downloadLinks,
  children,
  className = '',
}) => {
  const { t } = useTranslation();

  const defaultButtonOptions = {
    iconOptions: {
      icon: IconTypes.DOWNLOAD,
    } as ButtonLinkIconOptions,
    linkOptions: {
      isExternalLink: true,
      shouldOpenInNewTab: true,
    } as ButtonLinkLinkOptions,
    styleOptions: {
      type: ButtonLinkType.PRIMARY,
      size: ButtonLinkSize.SMALL,
      isInline: true,
      invertColors: true,
    } as ButtonLinkStyleOptions,
  };

  const headingToRender = typeof heading === 'string' ? <h4>{heading}</h4> : heading || null;

  const headingLabel =
    typeof heading === 'object' ? (heading as ReactElement)?.props.children : heading;

  const getButtons = () => {
    return downloadLinks.map((item, i) => {
      const opts = {
        to: item.to,
        ...defaultButtonOptions,
        ...item.buttonOptionOverrides,
      };
      return (
        <li key={`${item.label}-${i}`}>
          <ButtonLink
            {...opts}
            ariaLabel={`${t(`${LocaleNameSpaceKeys.COMMON}:download`)} ${headingLabel} ${
              item.label
            }`}
          >
            {item.label}
          </ButtonLink>
        </li>
      );
    });
  };

  return (
    <div className={`DownloadBlock ${className}`}>
      {headingToRender}
      {body}
      <ul className="DownloadBlock__buttons-wrapper">{getButtons()}</ul>
      {children}
    </div>
  );
};

export default memo(DownloadBlock);
