import React, { useState, useEffect, KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';

import Icon, { IconTypes } from '../Icon/Icon';
import { LocaleNameSpaceKeys } from '../../localization/translations';
import './style.scss';

interface OwnProps {
  className?: string;
  children: React.ReactNode[];
  timerDuration?: number;
  ariaNavItemLabels?: string[];
}

type Props = OwnProps;

const MagicCarousel: React.FC<Props> = ({
  children = [],
  className = '',
  timerDuration = 12000,
  ariaNavItemLabels,
}) => {
  const { t } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [ariaLive, setAriaLive] = useState<'off' | 'assertive' | 'polite'>('off');

  const handleClick = (index: any) => {
    setCurrentSlide(index);
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>, index: number) => {
    if (event.key === 'Enter') {
      setCurrentSlide(index);
    }
  };

  const handleNext = () => {
    if (currentSlide === ((children as any[]) || []).length - 1) {
      setCurrentSlide(0);
    } else {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const handlePrev = () => {
    if (currentSlide === 0) {
      setCurrentSlide(((children as any[]) || []).length - 1);
    } else {
      setCurrentSlide(currentSlide - 1);
    }
  };

  useEffect(() => {
    const carouselControls = document.querySelectorAll('.carousel-element');

    const onFocus = () => setAriaLive('polite');
    const onBlur = () => setAriaLive('off');

    if (carouselControls.length) {
      (carouselControls as NodeListOf<HTMLElement>).forEach((node) => {
        node.addEventListener('focus', onFocus);
        node.addEventListener('blur', onBlur);
      });
    }

    return () => {
      if (carouselControls.length) {
        (carouselControls as NodeListOf<HTMLElement>).forEach((node) => {
          node.removeEventListener('focus', onFocus);
          node.removeEventListener('blur', onBlur);
        });
      }
    };
  }, []);

  useEffect(() => {
    const next = (currentSlide + 1) % (children as any).length;
    const id = setTimeout(() => {
      setCurrentSlide(next);

      // if (document.activeElement?.classList.contains('carousel-element')) {
      //   const carouselItems = document.querySelectorAll('.carousel-slide');

      //   if (carouselItems && carouselItems.length) {
      //     (carouselItems[next] as HTMLButtonElement).focus();
      //   }
      // }
    }, timerDuration);
    return () => clearTimeout(id);
  }, [currentSlide]);

  const nextAriaLabel = t(`${LocaleNameSpaceKeys.COMMON}:carousel.next`);
  const previousAriaLabel = t(`${LocaleNameSpaceKeys.COMMON}:carousel.previous`);

  const getNavItemAriaLabel = (index: number) =>
    `${t(`${LocaleNameSpaceKeys.COMMON}:carousel.slideFormat`, {
      current: index + 1,
      total: children.length,
    })} ${ariaNavItemLabels?.[index] || ''}`;

  return (
    <div
      className="MagicCarousel"
      aria-roledescription={t(`${LocaleNameSpaceKeys.COMMON}:carousel.name`)}
    >
      <button
        className="prev carousel-element"
        onClick={handlePrev}
        aria-label={previousAriaLabel}
        aria-controls="carousel-items"
      >
        <Icon type={IconTypes.ARROW_NEXT_THIN} />
      </button>
      <div className={`CarouselWrapper ${className}`}>
        <div
          id="carousel-items"
          className="CarouselItems"
          style={{ marginLeft: `${currentSlide * -200}%` }}
          aria-live={ariaLive}
        >
          {children.map((child, index: number) => (
            <div
              role="group"
              key={`slide-${index}`}
              className={`CarouselItem${index === currentSlide ? '' : ' CarouselItem__hidden'}`}
              aria-roledescription={t(`${LocaleNameSpaceKeys.COMMON}:carousel.slide`)}
              aria-hidden={index !== currentSlide}
              aria-label={ariaNavItemLabels?.[index]}
            >
              {child}
            </div>
          ))}
        </div>
        <div className="Nav">
          {((children as any[]) || []).map((child, index) => (
            <div
              role="button"
              className={
                index === currentSlide
                  ? 'active carousel-element carousel-slide'
                  : 'carousel-element carousel-slide'
              }
              key={index}
              aria-label={getNavItemAriaLabel(index)}
              onClick={() => handleClick(index)}
              tabIndex={0}
              onKeyPress={(event) => handleKeyPress(event, index)}
            />
          ))}
        </div>
      </div>
      <button
        className="next carousel-element"
        onClick={handleNext}
        aria-label={nextAriaLabel}
        aria-controls="carousel-items"
      >
        <Icon type={IconTypes.ARROW_NEXT_THIN} />
      </button>
    </div>
  );
};

export default MagicCarousel;
